<template>
  <div class="hello">
    <h1>NOTTE DIVINA</h1>
    <h2>{{ msg }}</h2>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Lora&family=Noto+Serif&display=swap');

h1 {
  font-family: 'Noto Serif', serif;
  font-family: 'Lora', serif;
  font-family: 'Cinzel', serif;
  font-size: 10vw;
  font-weight: 400;
  margin-top: unset;
  margin-bottom: unset;
}

h2 {
  font-size: 1rem;
}

.hello {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
